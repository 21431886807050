"use client"; // Error components must be Client Components

import { typography } from "@evolve-storefront/ui/base/Typography/typography";
import { captureException } from "@sentry/nextjs";
import type { ReactNode } from "react";
import { useEffect } from "react";

// biome-ignore lint: lint/suspicious/noShadowRestrictedNames
export default function Error({
	error,
	reset,
}: {
	error: Error & { digest?: string };
	reset: () => void;
}): ReactNode {
	useEffect(() => {
		// Log the error to an error reporting service
		captureException(error);
		console.error(error);
	}, [error]);

	return (
		<html lang="en">
			<body>
				<div>
					<h2 className={typography("h2")}>Something went wrong!</h2>
					<button
						type="reset"
						onClick={
							// Attempt to recover by trying to re-render the segment
							() => reset()
						}
					>
						Try again
					</button>
				</div>
			</body>
		</html>
	);
}
